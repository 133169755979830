import m from 'mithril'
import img from '../img/about-us.jpg'

const AboutView = function(vnode){
  return {
    oncreate: () => {window.scrollTo(0,0);},
    view: () => (<div class="pure-g page">
      <div class="pure-u-1">
        <div class="col-left">
          <h2>About Us</h2>
          <p>Central Coast 4x4 Wreckers is an Australian family owned and run company and has been well established on the NSW Central Coast for the past 30 years. Our Quality Assurance Policy Guarantees our customers excellent service and quality spare parts.</p>
          <p>Central Coast 4x4 Wreckers prides itself in following a strict Environmental Policy implemented to follow the most up to date Recycling Procedures and Labour Saving Systems.</p>

          <p>Management of Central Coast 4x4 Wreckers have experienced many changes in the Vehicle Recycling Industry over their 30 years and have moved to the fore on all occasions, providing their customers and staff alike with newer and more environmentally friendly procedures with every new challenge.</p>

          <p>Freight for your part can be arranged locally, interstate and internationally, by reputable and reliable Couriers. We accept all major credit cards, direct bank transfers and also Eftpos is also available for your convenience.</p>

          <p>With over 5000 Vehicles already dismantled on our shelves and approx 500 vehicles currently onsite, Central Coast 4x4 Wreckers are proud to boast as being one of Australia's largest 4WD recyclers.</p>
        </div>
        <div class="col-right">
          <img src={img} class="pure-img" />
        </div>
      </div>
    </div>)
  }
}

export default AboutView
