import 'purecss';
import './css/style';
import m from 'mithril';

import Layout from './view/Layout';
import IndexView from './view/IndexView';
import AboutView from './view/AboutView';
import ContactView from './view/ContactView';
import CarsIndex from './view/CarsIndexView';
import BrandView from './view/BrandView';
import ModelView from './view/ModelView';
import PartsView from './view/PartsView';
import ProductView from './view/ProductView';

const parseRecords = data => ({
  id: data.id,
  name: data.label,
  slug: data.slug,
  asset: data.asset ? data.asset : null,
  make: data.make ? data.make : null,
  model: data.model ? data.model : null,
  img: data.image ? data.image.data.url :
    data.images && data.images.data.length > 0 ? data.images.data[0].url :
    'http://placehold.it/200x200'
});

const parseCars = data => ({
  id: data.id,
  name: data.label,
  slug: data.slug,
  img: data.featured_image ? data.featured_image.data.url : data.image ? data.image.data.url :
    data.images && data.images.data.length > 0 ? data.images.data[0].url :
    'http://placehold.it/200x200',
  thumb: data.featured_image ? data.featured_image.data.thumbnail_url : data.images && data.images.data.length > 0 ? data.images.data[0].thumbnail_url : ''
});

let state = {
  currentMake: null,
  makers: [],
  models: [],
  cars: [],
  car: {},
  justArrived: [],
  heroes: [],
  getMakers: e => {
    state.makers = []
    return m.request('/directus/api/1.1/tables/make/rows')
      .then(req => state.makers = req.data.map(parseRecords)) 
  },
  getModel: e => {
    state.models = []
    return m.request('/directus/api/1.1/tables/model/rows?filters[make][eq]=' + e.attrs.brand.split('-')[0])
      .then(req => state.models = req.data.map(parseRecords))
  },
  getCars: e => {
    state.cars = []
    return m.request('/directus/api/1.1/tables/assets/rows?filters[model][eq]=' + e.attrs.model.split('-')[0])
      .then(req => state.cars = req.data.map(parseCars))
  },
  getCar: e => {
    return m.request('/directus/api/1.1/tables/assets/rows/' + e.attrs.id.split('-')[0] + '?depth=2')
      .then(req => state.car = req.data)
  },
  getJustArrived: e => {
    return Promise.all([
      m.request('/directus/api/1.1/tables/hero_images/rows')
        .then(req => state.heroes = req.data.map(x => x.image.data.url)),
      m.request('/directus/api/1.1/tables/assets/rows?limit=8&order[id]=DESC&filters[featured]=1')
        .then(req => state.justArrived = req.data.map(parseCars))
    ]);
  }
};

// Setup Application Router
m.route.prefix("");
m.route(document.querySelector('#shell-outer'), "/", {
  "/": {
    oninit: state.getJustArrived,
    view: vnode => m(Layout, m(IndexView, {cars: state.justArrived, heroes: state.heroes}))
  },
  "/about": {view: () => m(Layout, m(AboutView)) },
  "/contact": {view: () => m(Layout, m(ContactView)) },
  "/contact/:id": {
    oninit: state.getCar,
    view: vnode => m(Layout, m(ContactView, {
      id: vnode.attrs.id,
      car: state.car
    }))
  },
  "/cars": {
    oninit: state.getMakers,
    view: vnode => m(Layout, m(CarsIndex, {makers: state.makers})),
    onupdate: p => window.scrollTo(0,0)
  },
  "/cars/:brand": {
    oninit: state.getModel,
    onupdate: p => {
      window.scrollTo(0,0);
      if(state.currentMake === p.attrs.brand){ return; }
      state.currentMake = p.attrs.brand;
      state.getModel(p);
    },
    view: vnode => {
      return m(Layout, m(BrandView, { brand: vnode.attrs.brand, models: state.models }));
    }
  },
  "/cars/:brand/:model": {
    oninit: state.getCars,
    onupdate: window.scrollTo(0,0),
    view: vnode => m(Layout, m(ModelView, { brand: vnode.attrs.brand, model: vnode.attrs.model, cars: state.cars }))
  },
  "/just-arrived/:id": {
    oninit: state.getCar,
    onupdate: window.scrollTo(0,0),
    view: vnode => m(Layout, m(ProductView, {
      id: vnode.attrs.id,
      car: state.car
    }))
  },
  "/cars/:brand/:model/:id": {
    oninit: state.getCar,
    onupdate: window.scrollTo(0,0),
    view: vnode => m(Layout, m(ProductView, {
      brand: vnode.attrs.brand,
      model: vnode.attrs.model,
      id: vnode.attrs.id,
      car: state.car
    }))
  },
  "/parts": {view: () => m(Layout, m(PartsView)) },
});
