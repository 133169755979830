import m from 'mithril'

const renderItem = slug => i => (<li key={i.id}>
  <a href={'/cars/' + slug + '/' + i.id + '-' + i.slug} oncreate={m.route.link}>
    <img src={i.img} />
    <span>{i.name}</span>
  </a>
</li>)

const MakeView = (vnode) => {
  return {
    view: (vnode) => (<div class="pure-g">
      <div class="pure-u-1">
        <div class="center-child"><h3>{vnode.attrs.model.split('-')[1] + (vnode.attrs.model.split('-')[2] ? ' ' + vnode.attrs.model.split('-')[2] : '')}</h3></div>
        { vnode.attrs.cars.length === 0 ? (<div class="loading"></div>) : null }
        <ul class="listing">
          {vnode.attrs.cars.map(renderItem(`${vnode.attrs.brand}/${vnode.attrs.model}`))}
        </ul>
      </div>
    </div>)
  }
}

export default MakeView
