import m from 'mithril'

let selection = 0
const selectImg = i => () => selection = i

function capitalize(string) {
  return !string ? '' : string.charAt(0).toUpperCase() + string.slice(1);
}

const ProductView = {
  oncreate: () => {window.scrollTo(0,0);},
  view: vnode => {
    const car = vnode.attrs.car ? vnode.attrs.car : {}
    const model = car.model ? car.model.data.label : ''
    let images = car.images ? car.images.data : []
    images = images && car.featured_image ? [car.featured_image.data].concat(images) : []
    const img = images.length > 0 ? images[selection].url : ''
    const make = car.model ? car.model.data.make.data.label : ''
    
    return (<div>
      <div class="center-child"><h3>{car.label}</h3></div>
      <div class="pure-g page">
        <div class="pure-u-2-3">
          <img class="pure-img" style="width:100%;" src={img} />
          <div class="table">
            <div><b>Make</b><span>{capitalize(make)}&nbsp;</span></div>
            <div><b>Transmission</b><span>{capitalize(car.transmission)}&nbsp;</span></div>
            <div><b>Model</b><span>{model}&nbsp;</span></div>
            <div><b>Fuel</b><span>{car.fuel}&nbsp;</span></div>
            <div><b>Engine</b><span>{car.engine}&nbsp;</span></div>
            <div><b>KM</b><span>{car.km}&nbsp;</span></div>
            <div><b>Body</b><span>{car.body}&nbsp;</span></div>
            <div><b>Colour</b><span>{car.colour}&nbsp;</span></div>
            <div style="display:none;"><b>Interior</b><span>{car.interior}&nbsp;</span></div>
            <div><b>Rego</b><span>{car.rego}&nbsp;</span></div>
          </div>
          <p><a class="btn" href={'/contact/' + car.id} oncreate={m.route.link}>Make an Enquiry</a></p>
        </div>
        <div class="pure-u-4-12 img-sidebar">
          { images.map((i,index) => m('div', {
              class:index === selection ? 'active' : ''
          },m('img.pure-img', {src: i.url, onclick: selectImg(index)}))) }
        </div>
      </div>
    </div>)
  }
}

export default ProductView
