import m from 'mithril'

const defaultState = {
  id: '',
  name: '',
  email: '',
  phone: '',
  body: '',
  subject: '',
  error: null
}
let state = Object.assign({}, defaultState)
let loading = false

const postMail = () => {
  loading = true
  return m.request({
    url: '/api/contact.php',
    method: 'POST',
    data: state,
    json: true
  }).catch(res => {
    loading = false
    state.error = 'Error sending message, please try again later'
  }).then(res => {
    loading = false
    if(res.ok === true){
      state = defaultState
      state.error = 'We have recieved your message, thank you!'
    } else {
      state.error = 'Error sending message, please try again later'
    }
  })
}

const validate = () =>
  !state.name ? 'Please provide a name' :
  !state.email || !state.phone ? 'Please provide a email or phone for us to contact you with' :
  !state.body ? 'Please enter a message for us' :
  !state.subject ? 'Please enter a subject for us' : true

const showError = error => state.error = error

const setValue = field => e => {
  state[field] = e.target.value
  state.error = null
}
const submitForm = e => validate() === true ? postMail() : showError(validate())

const ContactView = (vnode) => {
  return {
    oncreate: vnode => {
      console.log(vnode.attrs)
      if( (vnode.attrs.id && vnode.attrs.car.model) && state.error === null) {
        state.subject = "RE: " + vnode.attrs.car.label + ', ' + vnode.attrs.car.model.data.label
      }
      if(vnode.attrs.id){
        state.id = vnode.attrs.id
      }
      if(typeof vnode.attrs.id === 'undefined'){
        state.subject = ''
      }
      window.scrollTo(0,0)
    },
    view: vnode => (<div class="pure-g contact page">
      <div class="pure-u-1">
        <div class="col-left">
          <h2>Contact</h2>
          { state.error ? (<div>{state.error}</div>) : null }
          { loading ? (<div class="loading"></div>) : null }
          <form class="pure-form pure-form-aligned" onsubmit={submitForm}>
            <fieldset>
              <div class="pure-control-group">
                <label for="name">Name</label>
                <input id="name" name="name" type="text" placeholder="Your Name" oninput={setValue('name')} value={state.name} />
              </div>
              <div class="pure-control-group">
                <label for="email">Email</label>
                <input id="email" name="email" type="email" placeholder="Your Email" oninput={setValue('email')} value={state.email} email />
              </div>
              <div class="pure-control-group">
                <label for="phone">Phone</label>
                <input id="phone" name="phone" type="tel" placeholder="Your Contact Phone Number" oninput={setValue('phone')} value={state.phone} phone />
              </div>
              <div class="pure-control-group">
                <label for="subject">Subject</label>
                <input id="subject" name="subject" type="text" placeholder="Subject" oninput={setValue('subject')} value={state.subject} />
              </div>
              <div class="pure-control-group">
                <label for="body">Message Body</label>
                <textarea class="pure-u-1-3" id="body" oninput={setValue('body')} value={state.body}></textarea>
              </div>
              <div class="pure-controls">
                <a class="pure-button pure-button-primary" onclick={submitForm}>Submit</a>
              </div>
            </fieldset>
          </form>
        </div>
        <div class="col-right">
          <p>
            <b>Phone: </b>
            02 4352 2443
          </p>
          <h4>Hours of Trading</h4>
          <p>
            <b>Monday to Friday</b>: 8:30am to 5:00pm<br/>
            <b>Saturday</b>: 8:30am to 12:00pm
          </p>
        </div>
      </div>
    </div>)
  }
}

export default ContactView
