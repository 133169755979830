import m from 'mithril';

const renderItem = i => (<li key={i.id}>
  <a id={i.id} href={'/cars/' + i.id + '-' + i.slug} oncreate={m.route.link}>
    <img src={i.img} />
    <span>{i.name}</span>
  </a>
</li>)

const filterMake = make => make.model.data.length === 0 ? false : true

const CarsIndexView = (vnode) => {
  return {
    view: (vnode) => {
      return (<div class="pure-g">
        <div class="pure-u-1">
          <div class="center-child"><h3>Now Dismantling</h3></div>
          { vnode.attrs.makers.length ? (<ul class="listing">
            { vnode.attrs.makers.filter(filterMake).map(renderItem) }
          </ul>) : (<div class="loading"></div>)}
        </div>
      </div>)},
  }
}

export default CarsIndexView
