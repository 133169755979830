import m from 'mithril'

const renderItem = slug => i => (<li key={i.id}>
  <a href={'/cars/' + slug + '/' + i.id + '-' + i.slug} oncreate={m.route.link}>
    <img src={i.img} />
    <span>{i.name}</span>
  </a>
</li>)

const filterModel = model => model.asset.data.length === 0 ? false : true

const BrandView = (vnode) => {
  return {
    view: vnode => (<div class="pure-g">
      <div class="pure-u-1">
        <div class="center-child"><h3>{ vnode.attrs.brand.split('-')[1] + (vnode.attrs.brand.split('-')[2] ? ' ' + vnode.attrs.brand.split('-')[2] : '') }</h3></div>
          { vnode.attrs.models.length === 0 ? (<div class="loading"></div>) : null }
          <ul class="listing">{ vnode.attrs.models.filter(filterModel).map(renderItem(vnode.attrs.brand)) }</ul>
        </div>
      </div>)
  }
}

export default BrandView
