import m from 'mithril'
import Siema from 'siema';

const HeroView = (vnode) => {
  const nextSlide = () => vnode.slider.next()
  const prevSlide = () => vnode.slider.prev()

  return {
    view: vnode => {
      return (<div class="hero">
      <div class="hero-overlay">
        <h2>Australia's Leading<br /> 4x4 Wreckers</h2>
        <p>14 Lucca Road, Wyong, NSW, 2259</p>
        <a href="/cars" class="btn" oncreate={m.route.link}>Now Dismantling</a>
      </div>
      <a class="slider-left" onclick={prevSlide}></a>
      <a class="slider-right" onclick={nextSlide}></a>
      <div class="slider">
        { vnode.attrs.heroes.map(x => m('img', {src: x})) }
      </div>
      </div>)
    },
    oncreate: vnode => {
      vnode.slider = new Siema({
        duration: 1500,
        easing: 'ease-out',
        selector: vnode.dom.querySelector('.slider'),
        loop: true
      });
      vnode.interval = setInterval(() => vnode.slider.next(), 4000)
    },
    onremove: vnode => {
      clearInterval(vnode.interval)
      if(typeof vnode.slider !== 'undefined'){ vnode.slider.destroy() }
    }
  }
}

export default HeroView
