import m from 'mithril'
import MapImg from '../img/google-map.jpg'
import PhoneImg from '../img/phone-call.svg'
import PinImg from '../img/map-pin.svg'
import mailImg from '../img/mail.svg'
import brandImages from '../img/logos/*'
import Logo from '../img/logo.png'
import FbIcon from '../img/fb.svg'

const logoIds = {
  '1 - Mitsubishi.png': 24,
  '2 - Toyota.png': 26,
  '3 - Mazda.png': 22,
  '4 - Hyundai.png': 25,
  '5 - Honda.png': 29,
  '6 - Jeep.png': 27,
  '7 - Dodge.png': 23,
  '8 - Ford.png': 32,
  '9 - Nissan.png': 33,
  'Daihatsu.png': 31,
  'Holden.png': 28,
  'Kia.png': 37,
  'Land-Rover.png': 30,
  'Subaru.png': 36,
  'Suzuki.png': 34,
  'Great-Wall.png': 38
}

const getSlug = filename => logoIds[filename] + '-' +
                            filename.replace(/. - /,'').replace(/.png/,'').toLowerCase()

const Layout = {
  view: vnode => (<div id="shell-loaded">
    <div className="top-bar">
      <div class="pure-g">
        <div class="top-bar__content  pure-u">
          Call us now <a href="tel:0243522443" className="top-bar__phone">02 4352 2443</a>
        </div>
      </div>
    </div>
    <header class="pure-g">
      <div>
        <div class="logo-wrap">
          <a id="logo" href="/" oncreate={m.route.link}>Central Coast 4x4 Wreckers</a>
        </div>
        <nav>
          <a href="/" oncreate={m.route.link}>Home</a>
          <a href="/about" oncreate={m.route.link}>About</a>
          <a href="/parts" oncreate={m.route.link}>Parts Search</a>
          <a href="/cars" oncreate={m.route.link}>Now Dismantling</a>
          <a href="/contact" oncreate={m.route.link}>Contact</a>
        </nav>
        <a class="contact-hero" href="tel:0243522443">
          <i class="i-phone"></i>
          <span>02 4352 2443</span>
        </a>
      </div>
    </header>

    { vnode.children }

    <div class="brands">
      <div class="pure-g">
        <ul>
          { Object.entries(brandImages).map(i => m('li', m('img', {
              href: '/cars/' + getSlug(i[0]),
              oncreate: m.route.link,
              onclick: () => { window.scrollTo(0,0);m.route.set('/cars/' + getSlug(i[0])); },
              alt: getSlug(i[0]),
              src: i[1]
          }))) }
        </ul>
      </div>
    </div>

    <div id="map">
      <div>
        <img src={MapImg} />
      </div>
    </div>

    <div class="find-us pure-g">
      <div class="pure-u-1">
        <div class="details">
          <div class="left"><span>How to <b>Find Us</b></span></div>
          <div class="right">
            <p><img src={PhoneImg} /> <a href="tel:0243522443">02 4352 2443</a> </p>
            <p><img src={mailImg} /> <a href="mailto:sales@coast4x4wreckers.com.au">sales@coast4x4wreckers.com.au</a></p>
            <p><img src={PinImg} /> 14 Lucca Road, Wyong NSW, 2259</p>
            <p><img src={FbIcon} style="width:20px;" /> <a target="_blank" href="https://www.facebook.com/coast4x4wreckers/" rel="noopener noreferrer">Find us on facebook</a></p>
          </div>
        </div>
      </div>
    </div>

    <footer class="pure-g">
      <div class="pure-u-1">
        <nav>
          <a href="/" onclick="window.scrollTo(0,0)" oncreate={m.route.link}>Home</a>
          <a href="/about" oncreate={m.route.link}>About Us</a>
          <a href="/parts" oncreate={m.route.link}>Parts Search</a>
          <a href="/cars" oncreate={m.route.link}>Now Dismantling</a>
          <a href="/contact" oncreate={m.route.link}>Contact</a>
        </nav>
      </div>
      <p class="pure-u-1">
        Copyright &copy; 2018 Central Coast 4x4 Wreckers
      </p>
    </footer>

  </div>)
}

export default Layout
