import m from 'mithril'
import Siema from 'siema'
import testThumb from '../img/Test-thumbnail.jpg'

let slider = {next: () => {}, prev: () => {}}
const doSlider = vnode => {
  const isMobile = window.innerWidth >= 768 ? false : true
  if(vnode.attrs.cars.length > 0){
    if(slider.destroy){ slider.destroy() }
    slider = new Siema({
      selector: vnode.dom.querySelector('.siema'),
      perPage: isMobile ? 1 : 4,
      loop: true,
      duration: 1000
    });
  }
}

const JustArrivedView = (vnode) => {
  const nextSlide = () => slider.next()
  const prevSlide = () => slider.prev()

  return {
    view: vnode => (<div class="just-arrived pure-g">
      <div class="pure-u-1">
        <div class="center-child"><h3>Just Arrived</h3></div>
      </div>
      { vnode.attrs.cars.length > 0 ?
        (<div class="pure-u-1" style="position:relative;">
        <a class="slider-left" onclick={prevSlide}></a>
        <a class="slider-right" onclick={nextSlide}></a>
        <div class="siema">
          { vnode.attrs.cars.slice(-8).map(c =>
            (<a href={'/just-arrived/' + c.id} oncreate={m.route.link}><img src={c.thumb} /><span>{c.name}</span></a>))}
        </div>
      </div>) : (<div class="loading"></div>) }
    </div>),
    oncreate: vnode => {
      doSlider(vnode)
    },
    onupdate: vnode => {
      doSlider(vnode)
    }
  }
}

export default JustArrivedView
