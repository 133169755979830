import m from 'mithril'
import img from '../img/about-us.jpg'

const PartsView = function(vnode){
  return {
    oncreate: () => {window.scrollTo(0,0);},
    view: () => (<div class="pure-g page">
      <div class="pure-u-1">
        <h2>Parts Search</h2>
        <div className="responsive-iframe">
          <iframe src="https://ops.carparts-au.com/ops/ccfour/partSearch.php" width="100%" height="1200" frameBorder="0" style="border:0;" allowFullScreen></iframe>
        </div>
      </div>
    </div>)
  }
}

export default PartsView
