import m from 'mithril'
import HeroView from './HeroView'
import JustArrived from './JustArrivedView'

const IndexView = (vnode) => {
  return {
    oncreate: () => {window.scrollTo(0,0);},
    view: vnode => (<div>
      { vnode.attrs.heroes.length > 0 ? m(HeroView, {heroes: vnode.attrs.heroes}) : null }
      { m(JustArrived, {cars: vnode.attrs.cars}) }
    </div>)
  }
}

export default IndexView
